<template>
    <div class="videocall">
		<div class="call-container" >
	      <!-- The Daily Prebuilt iframe is embedded in the div below using the ref -->
	      <div class="call" ref="callRef"></div>
	    </div>
		<div class="calling-container" v-if="showCallFrame">
			<h2>Incoming Call</h2>
			<div class="row">
				<div class="img" :style="{'background-image':`url(/image/user/${callingContact.id})`}" />
			</div>
			<div class="row contact">
				<p class="name">{{ callingContact.forename }} {{ callingContact.surname }}</p>
				<p class="email">{{ callingContact.email }}</p>
			</div>
			<div class="row justify-content-center">
			<button v-on:click="accept" class="btn-phone accept"><div class="phone-ph-circle"></div>
			<div class="phone-ph-circle-fill"></div>
			<div class="phone-ph-img-circle"><i class="fas fa-phone"></i></div></button>
		</div>
		<div class="row justify-content-center">
			<button v-on:click="deny" class="btn-phone deny">
			<div class="phone-ph-img-circle"><i class="fas fa-phone"></i></div></button>
			</div>
		</div>

    </div>
</template>

<script>
import DailyIframe from "@daily-co/daily-js";

export default {
 data() {
   return {
     roomUrl: "",
     status: "home",
     callFrame: null,
     validRoomURL: false,
     roomError: false,
	 showCallFrame: false

    };
 },
 props: {
	 contact: {
	 	type: Object,
	 	default: null
	 },
	 user: {
	 	type: Object,
	 	default: null
	},
	callingContact: {
	   type: Object,
	   default: null
   },
	isCalling: {
		type: Boolean
	},
	isTalking: {
		type: Boolean
	}
},

 methods: {

 accept()
 {
	 console.log("accept");
	 this.showCallFrame = false;
	 this.$emit('acceptCall',this.roomUrl);

 },
 deny()
 {
	 console.log("deny call");
	 this.showCallFrame = false;
	 this.$emit('denyCall');
 },
   createAndJoinRoom() {
	   axios.get(`/room/${this.module_room_name}/module/${this.module_id}/api?action=request&user_target=`+this.contact.id)
	   	.then((event) => {
			console.log(event.data);
		console.log(event.data.room.dailyCo.url);
		   this.roomUrl = event.data.room.dailyCo.url;
		   this.joinRoom(this.roomUrl);
		   this.callPartner();
	   	});

   },
   calling(call)
   {
	   console.log("CALLING WITH",call);
	   this.joinRoom(call.roomUrl);
   },
   receiveCalling(roomUrl)
   {
	  this.roomUrl = roomUrl;
	  this.showCallFrame = true;
   },
   callPartner()
   {
	   Echo.private(`room.${this.module_room_name}.module.${this.module_id}.user.${this.contact.id}.whisper`)
		   .whisper('calling', {"user":this.user,"roomUrl":this.roomUrl});
   },
   cancelPartner()
   {
	Echo.private(`room.${this.module_room_name}.module.${this.module_id}.user.${this.contact.id}.whisper`)
		.whisper('cancel-calling', {"user":this.user,"roomUrl":this.roomUrl});
   },
   leftCall() {
	 	console.log("localUser left/canceled");
		//this.$emit('denyCall');
		console.log(this.isCalling);
		if(this.isCalling)
		{
			this.cancelPartner();
		}
		this.$emit('resetCall');
	 	this.showCallFrame = false;
		this.callFrame.destroy();
 	},
	remoteUserLeft() {
		 console.log("remote User left");
		 alert("User canceled your call.");
		 this.$emit('resetCall');
		this.callFrame.leave();
		 this.callFrame.destroy();
	 },
	cancelCall()
	{
		console.log("CANCEL CALL VIDEO CALL");
		this.callFrame.leave();
		this.showCallFrame = false;
		this.callFrame.destroy();
	},
	participantJoined()
	{
		this.$emit('callEstablished');
	},
   // Daily callframe created and joined below
   joinRoom(url) {
     if (this.callFrame) {
       this.callFrame.destroy();
	   this.showCallFrame = false;
     }

     // Daily event callbacks
     const logEvent = (ev) => console.log(ev);

     // DailyIframe container element
     const callWrapper = this.$refs.callRef;

     // Create Daily call
     const callFrame = DailyIframe.createFrame(callWrapper, {
       iframeStyle: {
         height: "auto",
         width: "100%",
         aspectRatio: 16 / 9,
         minWidth: "400px",
         maxWidth: "920px",
		 marginTop: "-31px"
       },
       showLeaveButton: true,
     });
     this.callFrame = callFrame;

     // Add event listeners and join call
     callFrame
       .on("loaded", logEvent)
       .on("started-camera", logEvent)
       .on("camera-error", logEvent)
       .on("left-meeting", this.leftCall) // local user left
	   .on("participant-left", this.remoteUserLeft) // remote user left
	   .on("participant-joined", this.participantJoined); // remote user left

     callFrame.join({
		 url : url,
		 userName : (this.user.forename +" "+ this.user.surname),
		 theme: {
	      colors: {
	        accent: '#631415'
	      }
	  }
  });
   },
 },
};
</script>

<style lang="scss" scoped>
.call
{
	overflow:hidden;
}
/*&.calling
{
	overflow-y: hidden;
}*/
.calling-container
{
	  background: white;
      width: calc(600px + 2em);
      height: calc(100% - 1.5rem);
      margin-top: -0.75rem;
      align-content: center;
      justify-content: center;
      display: grid;
      position: absolute;
	  text-align:center;
      left: 1.5rem;
      top: 1.5rem;
	  z-index:2;
	  .contact
	  {
		  text-align:center;
		  .name
		  {
			  font-size: 2em;
			  margin:0;
		  }
		  .email
		  {
			  color:var(--font-color-secondary);
			  margin:0;
		  }

	  }
	  .img {
		width: 100px;
		height: 100px;
		background-size: cover;
		border-radius: 50%;
		margin: 0 auto;
	  }
}

.phone-ph-circle {
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    position: absolute;
    background-color: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid rgba(30, 30, 30, 0.4);
    opacity: .1;
    -webkit-animation: phone-circle-anim 1.2s infinite ease-in-out;
    -moz-animation: phone-circle-anim 1.2s infinite ease-in-out;
    -ms-animation: phone-circle-anim 1.2s infinite ease-in-out;
    -o-animation: phone-circle-anim 1.2s infinite ease-in-out;
    animation: phone-circle-anim 1.2s infinite ease-in-out;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.btn-phone {
    background-color: transparent;
	border:0;
	height:200px;
	width:200px;
    cursor: pointer;
    z-index: 200000 !important;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -webkit-transition: visibility .5s;
    -moz-transition: visibility .5s;
    -o-transition: visibility .5s;
    transition: visibility .5s;
	&:focus {
	    outline: none;
	}
}

.btn-phone.accept .phone-ph-circle-fill {
    background-color: rgba(51, 168, 10, 0.5);
	opacity: .75 !important;
}

.phone-ph-circle-fill {
    width: 100px;
    height: 100px;
    top: 50px;
    left: 50px;
    position: absolute;
    background-color: #000;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: .1;
    -webkit-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -moz-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -ms-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -o-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.phone-ph-img-circle {
    width: 60px;
    height: 60px;
    top: 70px;
    left: 70px;
    position: absolute;
    background: rgba(30, 30, 30, 0.1);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    -webkit-animation: phone-circle-img-anim 1s infinite ease-in-out;
    -moz-animation: phone-circle-img-anim 1s infinite ease-in-out;
    -ms-animation: phone-circle-img-anim 1s infinite ease-in-out;
    -o-animation: phone-circle-img-anim 1s infinite ease-in-out;
    animation: phone-circle-img-anim 1s infinite ease-in-out;
}

.btn-phone.accept .phone-ph-img-circle {
    background-color: #33a867;
	text-align: center;
	i
	{
		font-size: 2em;
		line-height: 2em;
		color: white;
	}
}

.btn-phone.accept .phone-ph-circle {
    border-color: #33a867;
    opacity: .5;
}

.btn-phone.accept.phone-hover .phone-ph-circle-fill,
.btn-phone.accept:hover .phone-ph-circle-fill {
    background-color: #33a867;
    opacity: 1 !important;
}

.btn-phone.accept.phone-hover .phone-ph-img-circle,
.btn-phone.accept:hover .phone-ph-img-circle {
    background-color: white;
	i
	{
		color: #33a867;
	}
}
.btn-phone.deny
{
	height: 60px;
}
.btn-phone.deny .phone-ph-img-circle {
    background-color: #ff3b30;
	text-align: center;
	top:0;

	i
	{
		font-size: 2em;
		line-height: 2em;
		color: white;
	}
	-webkit-animation: none;
	-moz-animation: none;
	-ms-animation: none;
	-o-animation: none;
	animation: none;
}

.btn-phone.deny.phone-hover .phone-ph-img-circle,
.btn-phone.deny:hover .phone-ph-img-circle {
    background-color: white;
	i
	{
		color: #ff3b30;
	}
}

@-moz-keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@-webkit-keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@-o-keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@-moz-keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@-webkit-keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@-o-keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@-moz-keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}

@-webkit-keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}

@-o-keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}

@keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}
</style>
