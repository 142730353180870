require('../../../../../../resources/js/bootstrap');

"use strict";

window.Vue = require('vue');

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

export default async(module_id,actualRoom, settings) =>
{
    console.log('%c 💬 ChatModule Loaded! ', 'background: #222; color: #bada55');
    setupModule(module_id,actualRoom,settings);
    App.loadedModule(module_id,actualRoom);
}
async function setupModule(module_id,room,settings)
{
  if(!authenticated) return; // registration/active session required for this module
	Vue.component('chat-app', require('../vue/ChatApp.vue').default);
	Vue.prototype.settings = settings;
	Vue.prototype.module_room_name = room;
  Vue.prototype.module_id = module_id;

	const app = new Vue({
	    el: '#'+room+"_"+module_id
	});
	return;
}
