export default async(static_load) =>
{
    if(document.querySelector("#content .room_data") && static_load == undefined)
    {
        console.log('%c 🧹 previousRoom needs to be cleaned up! ', 'background: #0000ff; color: #FFF');
        /*let modules = JSON.parse(document.getElementById("room_data").dataset.modules);

          await Promise.all(modules.map(async (module) => {
                  //App.unloadModule(module.shortname,actualRoom,module.pivot.id,module.pivot.metadata.settings);
                  console.log(module.shortname);

          }));*/
          document.querySelector("#content .room_data").remove();

    }


    console.group('%c 🚪Room '+actualRoom+' Loaded! ', 'background: #ff0000; color: #FFF');
    //console.log(document.getElementById("room_data").dataset.modules);

    let modules = JSON.parse(document.getElementById("room_data").dataset.modules);

      await Promise.all(modules.map(async (module) => {
          if(module.preload_required == 1)
          {
              console.log("We'll wait for "+module.shortname+" (ID: "+module.pivot.id+") to render!");
              await App.loadModule(module.shortname,actualRoom,module.pivot.id,module.pivot.metadata.settings);
          }
          else {
              App.loadModule(module.shortname,actualRoom,module.pivot.id,module.pivot.metadata.settings);
          }
      }));

    console.log('%c 🚪 All preload_required Modules Loaded. Others might follow. ', 'background: #ff0000; color: #FFF');
    console.groupEnd();

}
