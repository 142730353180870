<template>
    <div class="conversation">
		<div class="container header">
			<div class="col text-start">
				<h2>{{ contact ? (contact.forename+" "+contact.surname) : "" }}</h2>
			</div>
			<div class="col text-end">
				<button v-if="settings.videocall === '1' && isCalling === false && isTalking === false && requestCalling === false" v-on:click="startVideoCall" class="btnVideoCall btn btn-primary"><i class="far fa-video"></i> Video call</button>
				<button v-on:click="closeConversation" class="btnCloseConversation btn btn-primary"><i class="far fa-arrow-right"></i></button>
			</div>
		</div>
		<VideoCall ref="VideoCall"  :isCalling="isCalling" :isTalking="isTalking" :callingContact="callingContact" :contact="contact" v-if='showCallFrame' :user="user" @denyCall="denyCall" @callEstablished="callEstablished" @resetCall="resetCall" @remoteUserLeft="remoteUserLeft" @acceptCall="acceptCall"/>
		<MessagesFeed :contact="contact" :messages="messages" :typingUser="typingUser" :chatVisible="chatVisible"/>
		<MessageComposer @send="sendMessage" :contact="contact" :user="user"/>
    </div>
</template>

<script>
    import MessagesFeed from './MessagesFeed';
    import MessageComposer from './MessageComposer';
	import VideoCall from './VideoCall';

    export default {
		data() {
            return {
                showCallFrame: false,
				requestCalling:false
            };
        },
        props: {
            contact: {
                type: Object,
                default: null
            },
			user: {
				type: Object,
				default: null
			},
            messages: {
                type: Array,
                default: []
            },
			typingUser: {
				type: [Object,Boolean]
			},
			chatVisible: {
				type: Boolean,
				default:false
			},
			callingContact: {
			   type: Object,
			   default: null
		   },
			isCalling: {
				type: Boolean
			},
			isTalking: {
				type: Boolean
			}
        },

		  methods: {
			startVideoCall(roomUrl) {
					console.log("initVideoCall");
					this.$emit('setCallingContact',this.contact);
					this.showCallFrame = true;
					this.requestCalling = true;
					this.$nextTick(() => {
					this.$emit('setIsCallingTrue');
					this.$emit('setIsTalkingTrue');
					this.$refs.VideoCall.createAndJoinRoom();
					});
		   	},
			receiveCalling(roomUrl)
			{
				console.log(roomUrl);
				this.showCallFrame = true;
				this.$nextTick(() => {
					this.$refs.VideoCall.receiveCalling(roomUrl);
				});
			},
			joinRoom(roomUrl) {
					console.log("joinRoom From Conversation");
					this.showCallFrame = true;
					this.$nextTick(() => {
					this.$refs.VideoCall.joinRoom(roomUrl);
					});
			},
			cancelCalling()
			{
				alert("User refused/canceled the call or is already in a call.");
				console.log(this.isTalking,this.isCalling);
				this.showCallFrame = false;
				this.requestCalling = false;
			},
			resetCall()
			{
				this.showCallFrame = false;
				this.requestCalling = false;
				this.$emit('resetCall');
			},
			cancelCall()
			{
				this.$refs.VideoCall.cancelCall();
			},
			callEstablished()
			{
				this.$emit('callEstablished');
			},
			closeConversation: function (event) {
			// `this` inside methods points to the Vue instance
			this.$emit('resetCall');
			this.$emit('closeConversation');

			// `event` is the native DOM event

			},
			denyCall()
		    {
				this.showCallFrame = false;
				this.requestCalling = false;
		   	 	this.$emit('denyCall');
		    },
			remoteUserLeft()
			{
				console.log("remote user left conversion");
				this.showCallFrame = false;
				this.requestCalling = false;
				this.$emit('setIsCallingFalse');
				this.$emit('setIsTalkingFalse');
			},
			acceptCall(url)
			{
			 this.$emit('acceptCall',url);
			},
            sendMessage(text) {
                if (!this.contact) {
                    return;
                }
                axios.post(`/room/${this.module_room_name}/module/${this.module_id}/api?action=conversation`, {
                    contact_id: this.contact.id,
                    text: text
                }).then((response) => {
                    this.$emit('new', response.data);
                })
            }
        },
        components: {MessagesFeed, MessageComposer,VideoCall}
    }
</script>

<style lang="scss" scoped>
.conversation {
	display: flex;
	flex-direction: column;
	background: var(--background-color-chat);
	border-radius:5px;
	border: solid var(--border-color) 1px;
	height: calc(100% - 2em);
	margin-left: 1em;
	margin-right: 1em;
    h2 {
    	font-size:18px;
		color: var(--font-color);
		margin: 0;
    }
	.header
	{
		background: var(--background-color-head);
		border-bottom: solid var(--border-color) 1px;
		display: flex;
		align-items: center;
		height:70px;
		.row
		{
			width:100%;
			.col
			{
				display: flex;
				align-items: center;
				padding:0;
				margin:0;
			}
		}
		button
		{
			border-radius: 30px;
			border:0;
			&.btnCloseConversation
			{
				background-color: var(--border-color);
				border-radius:50%;
			}
			&.btnVideoCall
			{
				background-color: var(--theme_color);
				margin-right:20px;

			}
		}

	}
}
</style>
