require('../../../../../../resources/js/bootstrap');

"use strict";

export default async(module_id,actualRoom,settings) =>
{
    console.log('%c 🔔 PushAnnouncementModule Loaded! ', 'background: #222; color: #bada55');
    setupModule(module_id,actualRoom);
    App.loadedModule(module_id,actualRoom);

    let notification_sound;
    let current_settings;

    async function setupModule(module_id,actualRoom)
    {
        const data = await (await fetch(`/room/${actualRoom}/module/${module_id}/api?action=metadata`)).json();
        current_settings = data.settings //enabled focus message
        

        if(current_settings.audio_play == 1 && current_settings.mp3 != "") notification_sound = new Audio(`/room/${actualRoom}/module/${module_id}/public?action=mp3`);
        [].forEach.call(data.user.groups, function(group) {
          if(data.group_everyone === group["id"])
          {
              Echo.channel(`room.${actualRoom}.module.${module_id}.group.${group["id"]}.update`)
              .listen('.Modules\\PushAnnouncement\\Events\\PushAnnouncement', async (event) => {
                 pushMessageToAnnouncementHistory(event.message)
              });
          }
          else {
              Echo.private(`room.${actualRoom}.module.${module_id}.group.${group["id"]}.update`)
              .listen('.Modules\\PushAnnouncement\\Events\\PushAnnouncement', async (event) => {
                 pushMessageToAnnouncementHistory(event.message)
              });
          }

          });

    }

    function pushMessageToAnnouncementHistory(message)
    {
      var currentdate = new Date();
      var currenttime = currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();

      var push_message_template = document.querySelector('#push_message').content.cloneNode(true);
      push_message_template.querySelector(".username").textContent = "HINWEIS";
      push_message_template.querySelector(".timestamp").textContent = currenttime;
      push_message_template.querySelector(".message").innerHTML = message;

      var push_history = document.getElementById("push_history");
      push_history.prepend(push_message_template);

      var toastElList = [].slice.call(document.querySelectorAll('.toast'))
            var toastList = toastElList.map(function(toastEl) {
            // Creates an array of toasts (it only initializes them)
              return new bootstrap.Toast(toastEl) // No need for options; use the default options
            });
           toastList.forEach(toast => {
               toast.show();
              toast._element.addEventListener('hidden.bs.toast', function () {
                    this.remove();
                });
           });
           try{
               if(current_settings.audio_play == 1 && current_settings.mp3 != "") notification_sound.play();
            }
            catch(e)
            {
                //console.log(e);
            }
    }
};
