require('../../../../../../resources/js/bootstrap');

'use strict';

let dailyco_instance;
let dailyco_bank;
let wait_room_audio;
let current_user;
let current_operator;
let current_settings;
let dailyco_loaded = false;

/**
 * Array of all rooms with user data, dailyCO data and settings data
 * @type {*[]}
 */
let roomList = [];

let bot_user = {
  'forename': 'Chat Bot',
  'surname': '',
  'position': '',
  'avatar_image': 'chatbot.jpg',
  'user_id': 0,
  'role': 'bot'
};

let module_id;

async function setupModule (remote_module_id, actualRoom) {
  module_id = remote_module_id;
  if (typeof (window.DailyIframe) !== 'function') {
    await new Promise((resolve, reject) => {
      var script = document.createElement('script');
      script.onload = () => resolve();
      script.src = 'https://rm-bpk2024.de/misc/daily31.js';
      document.head.appendChild(script);
    })
  }

  dailyco_bank = document.querySelector(`#module-instance-id-${module_id}_dailyco_bank`);

  const data = await (await fetch(`/room/${actualRoom}/module/${module_id}/api?action=metadata`)).json();
  current_settings = data.settings //enabled focus message

  //Set thankyou_message
  document.querySelector('#thankyou_message').innerHTML = current_settings.thankyou_message;

  //Set disabled module message
  document.querySelector('#disabled_message').innerHTML = current_settings.disabled_message;

  //Set disabled module instant message
  document.querySelector('#instant_disabled_message').innerHTML = current_settings.instant_disabled_message;
  [].forEach.call(data.user.groups, function (group) {
    if (group["id"] === data.group_everyone) return;
    Echo.private(`room.${actualRoom}.module.${module_id}.group.${group["id"]}.settings`)
      .listen('ModuleSettingsUpdate', async (event) => {
        if (event.settings.enabled == '0' && current_settings.enabled == '1') {
          disableModule();
        }
        else if (event.settings.enabled == '1' && current_settings.enabled == '0') {
          enableModule();
        }
        current_settings = event.settings
      });
  });
  // No instant question request
  if (current_settings.instant_question_request == '0') {
    document.querySelector('.askQuestion').removeEventListener('click', handleAskQuestion)
    document.querySelector('.askQuestion').addEventListener('click', handleAskQuestion);
  }
  // Module enabled and instant question request
  if (current_settings.enabled == '1' && current_settings.instant_question_request == '1') {
    setupClient();
  }
  else if (current_settings.enabled == '0' && current_settings.disabled_welcome_message_show == '1') {
    document.querySelector('.question_disabled_welcome_container').style.display = 'block';
  }
  if (current_settings.ask_again == '1') {
    document.querySelector('.askQuestionAgain').removeEventListener('click', handleAskQuestionAgain);
    document.querySelector('.askQuestionAgain').addEventListener('click', handleAskQuestionAgain);
  }
}

export async function setupClient () {
  if (current_settings.message != '') pushMessageToChatHistory('incoming', bot_user, current_settings.welcome_message);

  requestRoom();

  dailyco_instance = await window.DailyIframe.createCallObject();

  dailyco_instance
    .on('loaded', dailycoLoaded)
    .on('joining-meeting', joinedMeeting)
    .on('app-message', updateChat)
    .on('error', showEvent)
    .on('participant-joined', participantJoined)
    .on('participant-updated', participantUpdated)
    .on('participant-left', participantLeft)
    .on('track-started', trackStarted)
    .on('track-stopped', trackStopped);
}

function dailycoLoaded () {
  if (dailyco_loaded) return;
  setupButtons();
  setupWaitRoomAudio();
  dailyco_loaded = true;
}

function setupButtons () {
  document.querySelector('#button_chat_message').removeEventListener('click', handleSendChatMessage);
  document.querySelector('#button_chat_message').addEventListener('click', handleSendChatMessage);

  document.querySelector('#chat_form').removeEventListener('keypress', handleSendChatMessageByKey);
  document.querySelector('#chat_form').addEventListener('keypress', handleSendChatMessageByKey);

  document.querySelector('#dailyco_button_leave').removeEventListener('click', handleLeave);
  document.querySelector('#dailyco_button_leave').addEventListener('click', handleLeave);

  document.querySelector('#dailyco_button_toggle_chat').removeEventListener('click', handleToggleChat);
  document.querySelector('#dailyco_button_toggle_chat').addEventListener('click', handleToggleChat);

  if (current_settings.hide_toggle_av_buttons == '1') return;

  document.querySelector('#dailyco_button_toggle_audio').removeEventListener('click', handleToggleAudio);
  document.querySelector('#dailyco_button_toggle_audio').addEventListener('click', handleToggleAudio);

  document.querySelector('#dailyco_button_toggle_video').removeEventListener('click', handleToggleVideo);
  document.querySelector('#dailyco_button_toggle_video').addEventListener('click', handleToggleVideo);
}

function handleLeave () {
  deleteRoom(current_user);
  leaveCall();
}

function handleToggleChat () {
  toggleChat()
}

function handleToggleAudio () {
  dailyco_instance.setLocalAudio(!dailyco_instance.localAudio());
  document.querySelector('#dailyco_button_toggle_audio').classList.toggle('mute');
}

function handleToggleVideo () {
  dailyco_instance.setLocalVideo(!dailyco_instance.localVideo());
  document.querySelector('#dailyco_button_toggle_video').classList.toggle('mute');
}

function handleAskQuestion () {
  setupClient();
  document.querySelector('.question_disabled_welcome_container').style.display = 'none';
  document.querySelector('.question_button_container').style.display = 'none';
  document.querySelector('.question_thankyou_container').style.display = 'none';
  document.querySelector('.question_container').style.display = 'block';
}

function handleAskQuestionAgain () {
  requestRoom();
  document.querySelector('.question_disabled_welcome_container').style.display = 'none';
  document.querySelector('.question_button_container').style.display = 'none';
  document.querySelector('.question_thankyou_container').style.display = 'none';
  document.querySelector('.question_container').style.display = 'block';
}

function handleSendChatMessage () {
  if (document.querySelector('#input_chat_message').value == '') return;
  outgoingChatMessage(document.querySelector('#input_chat_message').value);
  document.querySelector('#input_chat_message').value = '';
}

function handleSendChatMessageByKey (event) {
  if (event.keyCode != 13) return;
  event.preventDefault();
  handleSendChatMessage();
}

async function deleteRoom (room) {
  //Delete dailyco room and room in db
  const result = await (await fetch(`/room/${actualRoom}/module/${module_id}/api?action=deleteRoomByUser`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(room)
  }));
}

function setupWaitRoomAudio () {
  if (current_settings.audio_play == 1 && current_settings.mp3 != '' && current_settings.instant_question_request != 1) wait_room_audio = new Audio(`/room/${actualRoom}/module/${module_id}/public?action=mp3`);
  if (current_settings.audio_loop == 1) wait_room_audio.loop = true;
  wait_room_audio?.play();
}
/* Event listener callbacks */

async function requestRoom () {
  const response = (await fetch(`/room/${actualRoom}/module/${module_id}/api?action=request`));
  const json = await response?.json();

  if (json.room.settings.status == 1) setKickstartPreview();
  else if (json.room.settings.status == 2) setKickstartLive();

  current_user = json;


  //Setup Websocket Event
  Echo.private(`room.${actualRoom}.module.${module_id}.user.${current_user.user.id}.settingsChanged`)
    .listen('.Modules\\Question\\Events\\DailyCoRoomUserSettingsChanged', async (event) => {
      if (current_user.user.id === event.dailyCoRoom.user.id) {
        if ((current_user.room.settings.status == 1 || current_user.room.settings.status == 2) && event.dailyCoRoom.settings.status == 0) {
          disableChat();
        }

        current_user.room = event.dailyCoRoom;

        switch (current_user.room.settings.status) {
          // User waiting
          case 0:
            setBackgroundIdle();
            break;

          // User preview
          case 1:
            setBackgroundPreview();
            break;

          // User live
          case 2:
            setBackgroundLive();
            break;
        }
      }
    });

  Echo.private(`room.${actualRoom}.module.${module_id}.user.${current_user.user.id}.deleted`)
    .listen('.Modules\\Question\\Events\\DailyCoRoomUserDeleted', async (event) => {
      leaveCall();
    });

  joinCall(json.room.dailyCo.name, json.user.id);

}

// Joins Daily call
// Passes the value in the 'room-url' input to dailyco_instance.join


async function joinCall (roomURL, user_id) {
  //dailyco_instance.participants().local.user_name = 'user_'+user_id;
  await dailyco_instance.join({
    url: current_settings.domain + roomURL,
    showLeaveButton: true,
    userName: 'user_' + user_id,
    subscribeToTracksAutomatically: false,
  });
  dailyco_instance.setUserName("user_" + user_id);

  dailyco_instance.participants().local.user_name = 'user_' + user_id;


}

/* Event listener callbacks */

// Logs the Daily event to the console
function showEvent (e) {
  console.log('dailyco_instance event', e);
}

/**
* Displays the chat window on user click
* Changes style of 'Chat' text from red, after new message, to white once clicked
*/
async function toggleChat () {
  document.querySelector('.chat').classList.toggle('hidden');
  document.querySelector('#dailyco_button_toggle_chat').classList.toggle('mute');
  //document.querySelector('#dailyco_instance').height = document.querySelector('#dailyco_instance').contentWindow.document.body.scrollHeight + 'px';
}

/**
* Updates a participant's local chat window display when the send button is clicked
* Uses sendAppMessage() to broadcast the message the participant typed to all participants
*/
async function outgoingChatMessage (message) {
  // Local update
  pushMessageToChatHistory('outgoing', current_user.user, message);
  dailyco_instance.sendAppMessage({ message: message }, '*');
}

/**
* Responds to an app-message event
* Appends a new 'p' element to the 'messages' div with the message the participant typed and their userName, if available
* Also updates the 'Chat' text to red when a new message has been sent
*/
async function updateChat (e) {
  pushMessageToChatHistory('incoming', current_operator, e.data.message);
}

async function leaveCall () {
  document.querySelector('.ui-controller').classList.remove('show');
  dailyco_instance?.leave();
  disableChat();
  dailyco_bank.innerHTML = "";

  Echo.leaveChannel(`private-room.${actualRoom}.module.${module_id}.user.${current_user.id}.settingsChanged`);
  Echo.leaveChannel(`private-room.${actualRoom}.module.${module_id}.user.${current_user.id}.deleted`);
  wait_room_audio?.pause();
  document.querySelector('.question_disabled_welcome_container').style.display = 'none';
  document.querySelector('.question_button_container').style.display = 'none';
  document.querySelector('.question_container').style.display = 'none';
  document.querySelector('.question_thankyou_container').style.display = 'block';
  document.querySelector('.question_disabled_container').style.display = 'none';
  setBackgroundIdle();
}

async function participantJoined (e) {
  const [rank, userID] = e.participant.user_name.split('_');
  if (rank === 'operator') {
    setNewOperator(e.participant, userID);
  } else if (rank == 'player') {
    setNewPlayer(e.participant);
  }
}

async function setNewPlayer (participant) {
  dailyco_instance.updateParticipant(
    participant.user_id,
    {
      setSubscribedTracks: { audio: true, video: true, screenVideo: false }
    }
  );
}

async function setNewOperator (participant, userID) {
  const users = await (await fetch(`/api/user/${userID}`)).json();
  current_operator = users[userID];
  wait_room_audio?.pause();
  document.querySelector('.question_container .loading_animation').style.display = 'none';
  dailyco_instance.updateParticipant(
    participant.user_id,
    { setSubscribedTracks: { audio: true, video: true, screenVideo: false } });
  enableChat();
}

async function participantLeft (e) {    // Get all the participants on the call
  const [rank, userID] = e.participant.user_name.split('_');
  if (rank === 'local' || rank === 'player') return; // 'You' is already added for the local user
  disableChat();
}

function joinedMeeting (event) {
  document.querySelector('.ui-controller').classList.add('show');
}
/**
*  Updates participant list based on who is in a call and triggers the chat to send to whoever was not in the meeting
*  @param {Object} e is the event information Daily returns
*/
async function participantUpdated (e) {
  const [rank, userID] = e.participant.user_name.split('_');
  if (rank === 'operator') {
    setNewOperator(e.participant, userID);
  } else if (rank == 'player') {
    setNewPlayer(e.participant);
  }
}

function setKickstartLive () {
  setBackgroundLive();
  document.querySelector('.question_button_container').style.display = 'none';
  document.querySelector('.question_container').style.display = 'block';
}

function setKickstartPreview () {
  setBackgroundPreview();
  document.querySelector('.question_button_container').style.display = 'none';
  document.querySelector('.question_container').style.display = 'block';
}

function pushMessageToChatHistory (type, user, message) {
  var currentdate = new Date();
  var currenttime = currentdate.getHours() + ':' + currentdate.getMinutes() + ':' + currentdate.getSeconds();

  // Tabelle mit dem existierenden HTML tbody und der Zeile (row) aus dem template Element instantiieren
  var chat_message_template = document.querySelector('#chat_message');
  let float_position_class = ''

  if (type == 'incoming') float_position_class = 'left';
  else if (type == 'outgoing') float_position_class = 'right';

  var chat_message_template = document.querySelector('#chat_message').content.cloneNode(true);
  chat_message_template.querySelector('li').classList.add(float_position_class);
  //chat_message_template.querySelector('.chat-img img').src = user.role=='bot'?'/img/avatars/'+user.avatar_image:'/image/user/'+user.id;
  chat_message_template.querySelector('.username').textContent = user.forename + ' ' + user.surname;
  chat_message_template.querySelector('.timestamp').textContent = currenttime;
  chat_message_template.querySelector('.message').textContent = message;

  var chat_history = document.querySelector('#chat_history');
  chat_history.appendChild(chat_message_template);

  document.querySelector('.chat .panel-body').scrollTop = document.querySelector('.chat .panel-body').scrollHeight;
}

function enableChat () {
  //Allow chat Message
  document.querySelector('#input_chat_message').disabled = false;
  if (document.querySelector('.chat.inactive') != null) document.querySelector('.chat.inactive').classList.remove('inactive')
  document.querySelector('.chat .username').innerHTML = current_operator.forename + ' ' + current_operator.surname;
}

function disableChat () {
  //Block chat Message
  document.querySelector('#input_chat_message').disabled = true;
  if (document.querySelector('.chat.inactive') == null) document.querySelector('.chat').classList.add('inactive')
  document.querySelector('.chat .username').innerHTML = '';
}

function enableModule () {
  document.querySelector('.askQuestion').removeEventListener('click', handleAskQuestion)
  document.querySelector('.askQuestion').addEventListener('click', handleAskQuestion);
  document.querySelector('.question_disabled_welcome_container').style.display = 'none';
  document.querySelector('.question_button_container').style.display = 'block';
  document.querySelector('.question_container').style.display = 'none';
  document.querySelector('.question_thankyou_container').style.display = 'none';
  document.querySelector('.question_disabled_container').style.display = 'none';
}

function disableModule () {
  if (current_user != null) {
    Echo.leaveChannel(`private-room.${actualRoom}.module.${module_id}.user.${current_user.user.id}.settingsChanged`);
    Echo.leaveChannel(`private-room.${actualRoom}.module.${module_id}.user.${current_user.user.id}.deleted`);
  }
  dailyco_instance?.leave();
  wait_room_audio?.pause();
  document.querySelector('.question_disabled_welcome_container').style.display = 'none';
  document.querySelector('.question_button_container').style.display = 'none';
  document.querySelector('.question_container').style.display = 'none';
  document.querySelector('.question_thankyou_container').style.display = 'none';
  document.querySelector('.question_disabled_container').style.display = 'block';
  document.querySelector('#chat_history').innerHTML = '';
  dailyco_bank.innerHTML = "";
  setBackgroundIdle();
}

function setBackgroundLive () {
  if (current_settings.show_tally_colors != '1') return;
  if (document.querySelector('.question_container.preview') != null) document.querySelector('.question_container.preview').classList.remove('preview');
  if (document.querySelector('.question_container.live') == null) document.querySelector('.question_container').classList.add('live');
}

function setBackgroundPreview () {
  try {
    //var videoTags = document.getElementById("iframe_livestream").contentWindow.document.getElementsByTagName('video')
    //for (var i = 0; i < videoTags.length; i++) {
    //videoTags.item(i).muted = true;
    //}
    player.mute();
  } catch (e) {
    console.log(e);
  }
  console.log("_____preview");
  if (document.querySelector('.modal.show') == null) document.querySelector('.modal').classList.add('show');
  if (current_settings.show_tally_colors != '1') return;
  if (document.querySelector('.question_container.live') != null) document.querySelector('.question_container.live').classList.remove('live');
  if (document.querySelector('.question_container.preview') == null) document.querySelector('.question_container').classList.add('preview');
}

function setBackgroundIdle () {
  try {
    //var videoTags = document.getElementById("iframe_livestream").contentWindow.document.getElementsByTagName('video')
    /*for (var i = 0; i < videoTags.length; i++) {
      videoTags.item(i).muted = false;
    } */
    player.unmute();
  } catch (e) {
    console.log(e);
  }
  document.querySelector('.wait_message').style.display = 'block';
  if (document.querySelector('.modal.show') != null) document.querySelector('.modal.show').classList.remove('show');
  if (document.querySelector('.question_container.live') != null) document.querySelector('.question_container.live').classList.remove('live');
  if (document.querySelector('.question_container.preview') != null) document.querySelector('.question_container.preview').classList.remove('preview');
}

function trackStarted (e) {
  const [rank, userID] = e.participant.user_name.split('_');
  //user module -> local audio mute
  //if (rank !== 'user' && !e.participant.local) return;
  if (e.track.kind === 'audio' && e.participant.local) return;

  let track = findAVParticipant(e.participant.session_id, e.track.kind);
  if (!track) {
    track = document.createElement(e.track.kind);
    track.dataset.session_id = e.participant.session_id;
    track.dataset.local = e.participant.local;
    track.autoplay = true;
    track.playsInline = true;
    dailyco_bank.appendChild(track);
  }
  track.srcObject = new MediaStream([e.track]);
}

function trackStopped (e) {
  showEvent('Track stopped: ', e);
  if (!e.track) return;
  let track = findAVTrack(e.track && e.track.id, e.track.kind);
  if (track) track.remove();
}

function findAVParticipant (session_id, type) {
  for (const track of document.getElementsByTagName(type)) {
    if (track.dataset.session_id === session_id) return track;
  }
}

function findAVTrack (trackID, type) {
  for (const track of document.getElementsByTagName(type)) {
    if (track.srcObject && track.srcObject.getTracks().find((t) => t.id === trackID)) return track;
  }
}

export default async (module_id, actualRoom, settings) => {
  console.log('%c ❓ QuestionModule Loaded! ', 'background: #222; color: #bada55');
  setupModule(module_id, actualRoom);
  App.loadedModule(module_id, actualRoom);
}
