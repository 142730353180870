<template>
	<div class="composer">
		<textarea v-model="message" @keydown.enter="send" @keydown="sendTypingEvent" placeholder="Enter your message here"></textarea>
		<button v-on:click="send" class="btn btn-primary"><i class="far fa-paper-plane"></i></button>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				message: ''
			};
		},
		props:
		{
			contact: {
				type: Object,
				default: null
			},
			user: {
				type: Object,
				default: null
			}
		},
		methods: {
			send(e) {
				e.preventDefault();

				if (this.message == '') {
					return;
				}

				this.$emit('send', this.message);
				this.message = '';
			},
			sendTypingEvent() {
				Echo.private(`room.${this.module_room_name}.module.${this.module_id}.user.${this.contact.id}.whisper`)
					.whisper('typing', this.user);
			}
        }
    }
</script>

<style lang="scss" scoped>
.composer
{
	display: flex;
	align-items: center;
	position: relative;
	textarea {
		width: calc(100% - 20px);
		margin: 10px;
		border-radius: 41px;
		resize: none;
		border: 1px solid lightgray;
		padding: 6px 10% 6px 20px;
		padding-top: 12px;
		height: 50px;
	}
	.btn
	{
		background-color: var(--theme_color);
		border-radius: 50%;
		border: 0;
		position: absolute;
		margin-right: 19px;
		right: 0;
	}
}
</style>
